import * as React from "react"
import GenericCard from "../../components/GenericCard"
import { graphql, navigate} from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Grid 
} from "@mui/material"
import BeGeneralPage from "../../components/be-general-page";
// import { isAdmin } from "@firebase/util"

const validateResearchersContent = {
  title: "Validate New Researchers",
  description: "Give permissions to new researchers to the platform and allow them to create experiments",
  buttonText: "Validate",
  callback: () =>{navigate('/administration/validate/');}
};

const manageGamesContent = {
  title: "Manage Game Permissions",
  description: "Manage the game permissions for researchers",
  buttonText: "Manage permissions",
  callback: () =>{navigate('managePermissions');}
};


const managePermissionsContent = {
  title: "Manage Researcher Permissions",
  description: "Manage the permissions and other options for each researcher",
  buttonText: "Manage permissions",
  callback: () =>{navigate('manageResearcherPermissions');}
};

const downloadDataContent = {
  title: "Manage Experiments",
  description: "Export data and manage any experiment",
  buttonText: "Explore experiments",
  callback: () =>{navigate('downloadExperiments');}
};

const deleteDataOfParticipantContent = {
  title: "Delete Participant Data",
  description: "Here you can delete all the data from an individual participant in every experiment",
  buttonText: "Search participant",
  callback: () =>{/* navigate('deleteParticipant'); */}
};

// markup
const IndexPage = () => {
	const { t } = useTranslation();

  return (
    <BeGeneralPage title={t("Administration Dashboard")}>
        <Grid container spacing={2} pt={4} pl={4}>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(validateResearchersContent.title)}
              description={t(validateResearchersContent.description)}
              buttonText={t(validateResearchersContent.buttonText)}
              onClick={validateResearchersContent.callback}
            />
          </Grid>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(manageGamesContent.title)}
              description={t(manageGamesContent.description)}
              buttonText={t(manageGamesContent.title)}
              onClick={manageGamesContent.callback}
            />
          </Grid>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(managePermissionsContent.title)}
              description={t(managePermissionsContent.description)}
              buttonText={t(managePermissionsContent.title)}
              onClick={managePermissionsContent.callback}
            />
          </Grid>
          <Grid item xs={4} pb={8}>
            <GenericCard
              title={t(downloadDataContent.title)}
              description={t(downloadDataContent.description)}
              buttonText={t(downloadDataContent.buttonText)}
              onClick={downloadDataContent.callback}
            />
          </Grid>
        </Grid>
    </BeGeneralPage>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "administration"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;